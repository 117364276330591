<template>
  <head>
    <title>オーダーメイド</title>
  </head>
  <aheader/>
  <!-- <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0"> -->
  <div class="container is-fluid">
    <div class="section">
      <p class="title">
        なぜ、オーダーメイドをするのか？
      </p>
    </div>
    <!-- <img height="400" width="600" style="float: bottom;" src="https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/order_made%2FOrderMade0_top2.png?alt=media&token=bfd707e8-7233-4f06-b9f5-428fc2b260a2"> -->
    <img height="400" width="600" style="float: bottom;" src="@/assets/OrderMade0_top2.png">
    <div class="block">
      <div class="content is-normal">
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <h4 style="line-height : 220%; text-align: left;">予防医療として、また次の日の活力を作るために睡眠は重要です。個人の体に合わせてオーダーメイドをすることで、ぐっすり眠ることができるようになります
              腰痛や肩こりのある方、妊婦さん、集中力を高めたい方はぜひ、オーダーメイドをご利用ください。</h4>
          </div>
        </div>
      </div>
      <!-- <img height="400" width="600" style="float: bottom;" src="@/assets/OrderMade0_top.png"> -->
    </div>
  </div>

    <div class="block">
      <div class="has-background-primary-light" style="padding-bottom:30px;">
        <div class="section">
          <p class="title">
            オーダーメイドの手順
          </p>
        </div>
        <div class="content is-normal">
          <div class="block" v-for="item, index in itemList" :key="index">
            <div class="columns">
              <div class="column is-8 is-offset-2">
                <h3 class="kyoutyou" style="margin-bottom:30px;">{{item.name}}</h3>
                <div class="container is-fluid">
                  <img height="500" width="600" style="float: top; margin-bottom:10px;" :src="item.icon">
                </div>
                <div class="column is-narrow">
                  <div class="container is-fluid">
                    <h4 class="has-text-left-touch" style="line-height : 220%; text-align: center;" :class="addTextLeft">{{item.kind}}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <button class="button is-medium has-background-danger-dark has-text-white-ter" @click="pushPage('application')">ご予約はこちらから</button>
        </div>
      </div>
    </div>


  <afooter/>
</template>

<style>
#measure{
  line-height: 2;
  text-align:left
}
#tejun{
  background-color: has-background-grey-lighter;
}
.kyoutyou{
  display:inline-block;
  padding:5px 0;
  border-top:5px double black;
  border-bottom:5px double black;
}
/* .o_picture {
  position: relative;
  width: 60vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.o_picture::after {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 10px 10px 10px 15px hsl(171, 100%, 96%);
} */

/* .o_picture img {
  width: 150%;
} */
</style>
<script>
  export default {
    data() {
      return {
        itemList: [
          // {name: "１．店舗にご来店", icon: "https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/order_made%2FOrderMade1_raiten.jpg?alt=media&token=55568aa1-6be3-4913-8ee0-430c7473eea6", kind: "事前にご予約の上、ご来店ください。お店は森小路の京かい道商店街にあります。のれんが目印です。"},
          // {name: "２.カウンセリング", icon: "https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/order_made%2FOrderMade2_counseling2.jpg?alt=media&token=fb488608-acb1-4536-b8f6-10ad9d5d09ca", kind: "お客様の好みや睡眠に対する悩み等を“眠りのプロ”がお聞きします。"},
          // {name: "３.測定", icon: "https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/order_made%2FOrderMade3_sokutei.png?alt=media&token=5d454165-4b18-4b78-b0a0-37469f8729cd", kind: "測定器を使い、身体の癖や寝姿勢をデータで確認いたします。おぐら屋が取り扱う、オーダーメイドの測定器は最先端の技術を活用してお客様に負担を与えずに測定をすることができます。オーダーメイドの測定器では体圧と体の左右の筋肉のバランスを測定します。"},
          // {name: "４．フィッティング", icon: "https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/order_made%2FOrdermde4_6_chousei_2.png?alt=media&token=ac92c9ce-2f38-4e6d-95ec-f22f414c8a86", kind: "実際のふとんで試し寝をしていただき、お客様に合うように微調整をしていきます。"},
          // {name: "５.ご購入", icon: "https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/order_made%2FOrderMade5_kounyu.png?alt=media&token=dde178dc-bd9a-4b1e-89e9-06b25671d119", kind: "気に入っていただけたらご購入へ。出来上がり次第、発送いたします。"},
          // {name: "６.再調整", icon: "https://firebasestorage.googleapis.com/v0/b/oguraya-test1.appspot.com/o/order_made%2FOrdermde4_6_chousei_2.png?alt=media&token=ac92c9ce-2f38-4e6d-95ec-f22f414c8a86", kind: "お家で使ってみて寝にくいようであればご相談ください。無料で再調整いたします。（半年以内、1回までとなります。）"}
          {name: "１．店舗にご来店", icon: require("@/assets/OrderMade1_raiten.jpg"), kind: "事前にご予約の上、ご来店ください。お店は森小路の京かい道商店街にあります。のれんが目印です。"},
          {name: "２.カウンセリング", icon: require("@/assets/OrderMade2_counseling2.jpg"), kind: "お客様の好みや睡眠に対する悩み等を“眠りのプロ”がお聞きします。"},
          {name: "３.測定", icon: require("@/assets/OrderMade3_sokutei.png"), kind: "測定器を使い、身体の癖や寝姿勢をデータで確認いたします。おぐら屋が取り扱う、オーダーメイドの測定器は最先端の技術を活用してお客様に負担を与えずに測定をすることができます。オーダーメイドの測定器では体圧と体の左右の筋肉のバランスを測定します。"},
          {name: "４．フィッティング", icon: require("@/assets/Ordermde4_6_chousei_2.png"), kind: "実際のふとんで試し寝をしていただき、お客様に合うように微調整をしていきます。"},
          {name: "５.ご購入", icon: require("@/assets/OrderMade5_kounyu.png"), kind: "気に入っていただけたらご購入へ。出来上がり次第、発送いたします。"},
          {name: "６.再調整", icon: require("@/assets/Ordermde4_6_chousei_2.png"), kind: "お家で使ってみて寝にくいようであればご相談ください。無料で再調整いたします。（半年以内、1回までとなります。）"}
        ],
        modalClass: ""
      }
    },
    mounted() {
      this.scrollTop();
    },
    computed: {
      addTextLeft() {
        let screenSize = document.body.offsetWidth
        //console.log(screenSize)
        if (screenSize <= 768) {
          //console.log(screenSize)
          return "has-text-left"
        } else {
          return null
        }
      }
    },
    methods: {
      pushPage(num) {
        this.$router.push(num);
      },
      scrollTop() {
        this.$nextTick(() => {
          window.scrollTo(0, 0);
        })
      },
    }
  }
</script>
